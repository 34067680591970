import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';  // Adjust this path if needed

// Ensure you're rendering to the 'root' element
const rootElement = document.getElementById('root');
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);  // React 18 syntax
  root.render(<App />);
} else {
  console.error('Root element not found');
}
