import React, { useEffect, useState } from 'react';

function PropertyListPage() {
  const [properties, setProperties] = useState([]);

  useEffect(() => {
    // Fetch properties from the Django API
    fetch('/api/properties/')
      .then(response => response.json())
      .then(data => setProperties(data))
      .catch(error => console.error('Error fetching properties:', error));
  }, []);

  return (
    <div>
      <h1>Properties for Sale</h1>
      <ul>
        {properties.length > 0 ? (
          properties.map(property => (
            <li key={property.id}>
              {property.address} - {property.price} SEK
              <br />
              {property.details}
              <br />
              <img src={property.photo_url} alt={property.address} width="200" />
            </li>
          ))
        ) : (
          <li>No properties available at the moment.</li>
        )}
      </ul>
    </div>
  );
}

export default PropertyListPage;
